import { DOCUMENT } from '@angular/common';
import { EnvironmentProviders, isDevMode, makeEnvironmentProviders, inject, provideAppInitializer } from '@angular/core';
import { TRANSLOCO_CONFIG, TranslocoService, provideTransloco, translocoConfig } from '@jsverse/transloco';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { WA_WINDOW } from '@ng-web-apis/common';
import { Environment } from '@rma/generic/util/environment';
import { firstValueFrom } from 'rxjs';
import { TranslocoHttpLoader } from './data-access-loader/translation-loader.service';

export function provideRmaTranslation(): EnvironmentProviders {
  const initializers = [
    provideAppInitializer(() => {
      const initializerFn = initialiseLangTag(inject(Environment), inject(DOCUMENT));
      return initializerFn();
    }),
    provideAppInitializer(() => {
      const initializerFn = preloadTranslation(inject(Environment), inject(TranslocoService), inject(WA_WINDOW));
      return initializerFn();
    }),
  ];
  // provideTransloco conditionally pushes the TRANSLOCO_CONFIG, but it isn't optional on the interface.
  // The any allows us set the config but transloco still sets up the other providers
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const providerConfig = { loader: TranslocoHttpLoader } as any;
  return makeEnvironmentProviders([
    ...initializers,
    ...provideTransloco(providerConfig),
    provideTranslocoFactoryConfig(),
    provideTranslocoMessageformat(),
  ]);
}

function provideTranslocoFactoryConfig() {
  return makeEnvironmentProviders([
    {
      provide: TRANSLOCO_CONFIG,
      useFactory: (env: Environment, window: Window) => {
        const supportedLangs = ['en-US', 'en-AU', 'en-NZ', 'es-US'];
        const urlParams = new URLSearchParams(window.location.search);
        const customLang = urlParams.get('setLang');
        const defaultLang = customLang ?? env.language.locale;

        const config = {
          availableLangs: supportedLangs,
          defaultLang: defaultLang,
          fallbackLang: 'en-AU',
          reRenderOnLangChange: true,
          prodMode: !isDevMode(),
        };
        return translocoConfig(config);
      },
      deps: [Environment, WA_WINDOW],
    },
  ]);
}

// See https://github.com/ngx-translate/core/issues/565 for when this is redundant
function initialiseLangTag(config: Environment, document: Document) {
  return () => {
    document.documentElement.lang = config.language.locale;
  };
}

function preloadTranslation(config: Environment, transloco: TranslocoService, window: Window) {
  return () => {
    const urlParams = new URLSearchParams(window.location.search);
    const customLang = urlParams.get('setLang');
    const defaultLang = customLang ?? config.language.locale;
    transloco.setActiveLang(defaultLang);
    transloco.setDefaultLang(defaultLang);
    return firstValueFrom(transloco.load(config.language.locale, { fallbackLangs: ['en-AU'] }));
  };
}
